/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

* {
  font-family: "PT Sans Narrow", sans-serif;
  text-decoration: none !important;
  letter-spacing: 0.7px;
}
body {
  background-color: #d4d4d5;
}
a {
  cursor: pointer;
  color: #4d6f97;
  &:hover {
    color: #2f4e7f;
  }
}
textarea:focus,
input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}
.input-group {
  position: inherit !important;
}
.destaque {
  color: #4d6f97;
  font-size: 18px;
}
.sombra-personalizada {
  box-shadow: 2px 2px 6px #a5a5a5;
}

//estilo dos títulos das páginas
.titulo-pagina {
  color: #2f4e7f;
  text-align: left;
  font-size: 50px;
  font-weight: 700;
  margin: 0px;
}
.titulo-card {
  font-size: 30px;
  font-weight: 700;
  color: #4d6f97;
  text-align: left;
  width: 100%;
  margin: 0px;
}
fa-icon.ng-fa-icon {
  font-size: 2em;
  margin-right: 5px;
  color: #2f4e7f;
}
@media (max-width: 425px) {
  .titulo-pagina {
    font-size: 30px;
    margin-left: 5px;
  }
  .titulo-card {
    font-size: 25px;
  }
}

//estilo dos cards que delimita o conteúdo das páginas
.card {
  background-color: white;
  border-radius: 4px;
  border-left: 10px solid #4d6f97;
  padding: 16px;
  position: inherit !important;
  box-shadow: 2px 2px 6px #a5a5a5;
}

.banner {
  box-shadow: 2px 2px 6px #a5a5a5;
}

//estilo do botão padrão
button {
  color: white;
  background-color: #4d6f97;
  border: none;
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 2px;
  text-align: center;
  align-items: center !important;
  &:hover:not(.btn-close){
    cursor: pointer;
    background-color: #2f4e7f;
  }
}

//estilo geral das listas
.lista {
  &:last-of-type {
    border: none !important;
    margin-bottom: 0px !important;
  }
}
.item-lista {
  list-style-type: none;
  font-size: 15px;
}
